var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-between align-items-end"},[_c('div',{staticClass:"col row"},[_c('div',{staticClass:"col-3"},[_vm._m(0),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.deposit_reference),expression:"deposit_reference"}],staticClass:"form-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.deposit_reference=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.updateFilteredOutStocks]}},[_c('option',{attrs:{"value":""}},[_vm._v("---")]),_vm._l((_vm.deposits),function(deposit,index){return _c('option',{key:index++,domProps:{"value":deposit.reference}},[_vm._v(" "+_vm._s(deposit.fullName)+" ")])})],2)]),_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":""}},[_vm._v("Catégorie")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.section_reference),expression:"section_reference"}],staticClass:"form-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.section_reference=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.updateFilteredOutStocks]}},[_c('option',{attrs:{"value":""}},[_vm._v("---")]),_vm._l((_vm.sections.filter(
              (p) =>
                p.deposit_reference != null &&
                p.deposit_reference == _vm.deposit_reference
            )),function(section,index){return _c('option',{key:index++,domProps:{"value":section.reference}},[_vm._v(" "+_vm._s(section.fullName)+" ")])})],2)]),_c('div',{staticClass:"col-4"},[_c('label',{attrs:{"for":""}},[_vm._v("Produit")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.item_reference),expression:"item_reference"}],staticClass:"form-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.item_reference=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.updateFilteredOutStocks]}},[_c('option',{attrs:{"value":""}},[_vm._v("---")]),_vm._l((_vm.items.filter(
              (p) => p.section_reference == _vm.section_reference
            )),function(item,index){return _c('option',{key:index++,domProps:{"value":item.reference}},[_vm._v(" "+_vm._s(item.fullName)+" ")])})],2)])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-info",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"bi bi-arrow-clockwise fs-5"}),_c('span',[_vm._v(" Actualiser ")])])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.print()}}},[_c('i',{staticClass:"bi bi-printer fs-5"}),_c('span',[_vm._v(" Imprimer ")])])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.filteredOutStocks),function(item,index){return _c('tr',{key:index++},[_c('td',[_vm._v(" "+_vm._s(index)+" ")]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn btn-link ms-2",on:{"click":function($event){return _vm.$router.push({
                name: 'profile-stock',
                params: {
                  reference: item.reference,
                },
              })}}},[_vm._v(" "+_vm._s(item.reference)+" ")])]),_c('td',[_c('div',[_vm._v(" "+_vm._s(item.fullName)+" ")])]),_c('td',[(item.section)?_c('div',[_vm._v(" "+_vm._s(item.section.fullName)+" ")]):_vm._e()]),_c('td',{staticClass:"p-0 m-0"},[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push({
                name: 'stocks-create',
                params: { itemReference: item.reference },
              })}}},[_c('i',{staticClass:"bi bi-plus-square fs-5 text-success"})])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_vm._v("Dépôt "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}}),_c('th',{attrs:{"scope":"col"}},[_vm._v("Référence")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Désignation")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Catégorie")]),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }